let metadata = {
    "9999": {
        "name": "load error",
        "twitterPicURL": "",
        "star": "☆0"
    },
    "0": {
        "name": "vividot (3D edition)",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "3D"
            }
        ],
        "twitterPicURL": "",
        "star": "☆7"
    },
    "1": {
        "name": "duck (3D edition)",
        "attributes": [
            {
                "trait_type": "base",
                "value": "duck"
            },
            {
                "trait_type": "optional",
                "value": "3D"
            }
        ],
        "twitterPicURL": "",
        "star": "☆7"
    },
    "2": {
        "name": "manekineko (special edition)",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "manekineko"
            },
            {
                "trait_type": "optional",
                "value": "kanji"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418816344972095488/photo/1",
        "star": "☆6"
    },
    "3": {
        "name": "unicorn (special edition)",
        "attributes": [
            {
                "trait_type": "base",
                "value": "unicorn"
            },
            {
                "trait_type": "optional",
                "value": "kanji"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418816317935689729/photo/1",
        "star": "☆6"
    },
    "4": {
        "name": "sushi (special edition)",
        "attributes": [
            {
                "trait_type": "base",
                "value": "sushi"
            },
            {
                "trait_type": "optional",
                "value": "kanji"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418816293247979522/photo/1",
        "star": "☆6"
    },
    "5": {
        "name": "manekineko",
        "attributes": [
            {
                "trait_type": "base",
                "value": "sushi"
            },
            {
                "trait_type": "optional",
                "value": "manekineko"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418816136750112774/photo/1",
        "star": "☆5"
    },
    "6": {
        "name": "unicorn",
        "attributes": [
            {
                "trait_type": "base",
                "value": "unicorn"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418816112448311296/photo/1",
        "star": "☆5"
    },
    "7": {
        "name": "sushi",
        "attributes": [
            {
                "trait_type": "base",
                "value": "sushi"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418816087584505856/photo/1",
        "star": "☆5"
    },
    "8": {
        "name": "giant 3D cat",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "billboard"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418815945653440513/photo/1",
        "star": "☆4"
    },
    "9": {
        "name": "megane cat",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "megane"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418815923998236673/photo/1",
        "star": "☆4"
    },
    "10": {
        "name": "vixe",
        "attributes": [
            {
                "trait_type": "base",
                "value": "vixe"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418815897368621057/photo/1",
        "star": "☆4"
    },
    "11": {
        "name": "chimera",
        "attributes": [
            {
                "trait_type": "base",
                "value": "chimera"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418813647027986432/photo/1",
        "star": "☆3"
    },
    "12": {
        "name": "gelato cat",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "gelato"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418813621555978241/photo/1",
        "star": "☆3"
    },
    "13": {
        "name": "ramen cat",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "ramen"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418813480677773313/photo/1",
        "star": "☆3"
    },
    "14": {
        "name": "giraffe",
        "attributes": [
            {
                "trait_type": "base",
                "value": "giraffe"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418813454601781249/photo/1",
        "star": "☆2"
    },
    "15": {
        "name": "chick",
        "attributes": [
            {
                "trait_type": "base",
                "value": "chick"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418813428378988544/photo/1",
        "star": "☆2"
    },
    "16": {
        "name": "dinosaur",
        "attributes": [
            {
                "trait_type": "base",
                "value": "dinosaur"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418809487423537152/photo/1",
        "star": "☆2"
    },
    "17": {
        "name": "goast",
        "attributes": [
            {
                "trait_type": "base",
                "value": "goast"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418809410982346754/photo/1",
        "star": "☆2"
    },
    "18": {
        "name": "fish",
        "attributes": [
            {
                "trait_type": "base",
                "value": "fish"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418809336097218561/photo/1",
        "star": "☆2"
    },
    "19": {
        "name": "lion",
        "attributes": [
            {
                "trait_type": "base",
                "value": "lion"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418808862610628609/photo/1",
        "star": "☆2"
    },
    "20": {
        "name": "mosaic",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "mosaic"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418805653221482497/photo/1",
        "star": "☆1"
    },
    "21": {
        "name": "cat＃5",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "Dot"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418805454025547776/photo/1",
        "star": "☆1"
    },
    "22": {
        "name": "cat＃4",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "zzz"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418805340934610945/photo/1",
        "star": "☆1"
    },
    "23": {
        "name": "cat＃3",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418805260177461248/photo/1",
        "star": "☆1"
    },
    "24": {
        "name": "cat＃2",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418804829489557508/photo/1",
        "star": "☆1"
    },
    "25": {
        "name": "cat＃1",
        "attributes": [
            {
                "trait_type": "base",
                "value": "cat"
            },
            {
                "trait_type": "optional",
                "value": "none"
            }
        ],
        "twitterPicURL": "https://twitter.com/vividot_nft/status/1418803502311108610/photo/1",
        "star": "☆1"
    }
}

module.exports = metadata;