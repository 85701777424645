<template>
  <v-app>
    <v-container width="1200px">
      <v-card class="mb-10">
        <v-img src="./assets/logo.png" class="" width="100%"> </v-img>
        <v-card-title class="pb-0">ビビドットNFTについて</v-card-title>
        <v-card-text class="pb-0 font-weight-bold">
          ビビドットNFTガチャとは全24種類のビビドットのNFTが出てくるガチャです。<br />
          本NFTはEthereumのサイドチェーンであるPolygonにデプロイされています。<br />
          ガス代となるMATICの配布も行っていますので、今までPolygonをご利用したことがない方もお楽しみいただけます。<br />
          また、<a
            href="https://opensea.io/assets/matic/0x445dc5285bb5de6263bbbf6edfd4e0fdac397f6b/1"
            target="_blank"
            >25個のみmintされた3DアヒルNFT</a
          >がプレゼントキャンペーンに、<a
            href="https://opensea.io/assets/matic/0x445dc5285bb5de6263bbbf6edfd4e0fdac397f6b/0"
            target="_blank"
            >1個のみmintされた3D猫NFT</a
          >が<a
            href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/38230108699107172368714820119057213175155539209099590904327005467974475710465"
            target="_blank"
            >オークション</a
          >に割り当てられています（ガチャから出てくる24種類に含まれません）。<br />
          詳細については次の記事をご参照ください。<br />
          <a href="https://vividot-de.fi/entry/vividot-nft" target="_blank"
            >ビビドットNFTガチャを作ってみた【Matic配布/プレゼントキャンペーン/オークション全部盛り】</a
          >
        </v-card-text>
        <v-card-title class="pb-0">取得したNFTについて</v-card-title>
        <v-card-text class="pb-0 font-weight-bold">
          取得したNFTは本ページ下部で確認できます。読み込み時間を短縮するためにNFTの表示を1ページあたり24個に制限しています。<br />
          所持しているNFTの全てを一覧でみたい場合や絞り込みを行いたい場合は<a
            href="https://opensea.io/collection/vividot-nft?locale=ja&search[sortAscending]=false&search[sortBy]=CREATED_DATE"
            target="_blank"
            >OpenSea(ビビドットNFTトップページ)</a
          >をご利用ください。
          <br />
          NFTの名前と併記される出現率は、排出確率ではなくミントされた全てのNFTにおける割合を示しています。
        </v-card-text>
        <v-card-title class="pb-0">利用規約</v-card-title>
        <v-card-text class="pb-0 font-weight-bold">
          本NFTの所有者は、本NFTの対象となる画像を「SNS等においてアイコンにする行為」及び商用利用を除き、自由に利用することができます。著作権や商標権その他の知的財産権は譲渡されません。
        </v-card-text>
        <v-card-title class="pb-0">お知らせ</v-card-title>
        <v-card-text class="pb-0 font-weight-bold">
          ■厳密にサイトを作り込んでいないので、エラーが出た際やガチャ後のクルクルが終わらない場合はページの更新をお願いいたします。<br />
          ■また、MaticのRPCの問題でTXが通らない場合はRPC
          URLの変更などをお試し下さい。<br />
          ■何かありましたらDiscordの #サポート まで
        </v-card-text>
        <v-btn
          outlined
          x-large
          block
          color="warning"
          v-on:click="gacha"
          class="mt-5"
        >
          ガチャを回す（無料）
        </v-btn>
      </v-card>

      <v-row dense>
        <v-col
          v-for="token in tokens"
          :key="token.id"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          xl="2"
        >
          <v-card>
            <v-img :src="token.src" class="white--text align-end"> </v-img>

            <v-card-title v-text="token.name"></v-card-title>
            <v-card-text class="pb-0">
              <div class="font-weight-bold">
                {{ token.star }} - 出現率{{ token.rarity }} %
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                icon
                :href="
                  encodeURI(
                    'https://twitter.com/intent/tweet?text=' +
                      token.name +
                      '(現在の出現率' +
                      token.rarity +
                      '%)が当たりました！\n' +
                      token.twitterPicURL +
                      '\nhttps://nft.vividot-de.fi/' +
                      '&hashtags=ビビドットNFTガチャ'
                  )
                "
                target="_blank"
              >
                <v-img src="./assets/twitterLogo.png" max-width="30px"></v-img>
              </v-btn>
              <v-btn
                icon
                :href="
                  'https://opensea.io/assets/matic/' +
                  vividotNFTContract.options.address +
                  '/' +
                  token.id
                "
                target="_blank"
              >
                <v-img src="./assets/openSeaLogo.png" max-width="30px"></v-img>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-pagination
        v-model="page"
        :length="pageLength"
        class="mt-3"
        @input="loadNFT"
      ></v-pagination>
    </v-container>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} - <strong>vividot</strong>
      </v-col>
    </v-footer>

    <!--<v-btn color="warning" v-on:click="setBaseURI">Change MetadataURI</v-btn>-->

    <v-overlay :value="loading" align="center">
      <v-progress-circular
        indeterminate
        size="64"
        class="mb-5"
      ></v-progress-circular
      ><br />
      <p class="white-text">
        所持しているNFTを読み込んでいます {{ tokens.length }}/{{
          Math.min(balance, 24)
        }}
      </p>
    </v-overlay>

    <v-overlay :value="waitGacha">
      <v-img src="./assets/gacha.gif" max-width="300px"></v-img>
    </v-overlay>

    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-img :src="lastTokenInfo.src" class="white--text align-end"> </v-img>

        <v-card-title v-text="lastTokenInfo.name"></v-card-title>
        <v-card-text class="pb-0">
          <div class="font-weight-bold">
            {{ lastTokenInfo.star }} - 出現率{{ lastTokenInfo.rarity }} %
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            icon
            :href="
              encodeURI(
                'https://twitter.com/intent/tweet?text=' +
                  lastTokenInfo.name +
                  '(現在の出現率' +
                  lastTokenInfo.rarity +
                  '%)が当たりました！\n' +
                  lastTokenInfo.twitterPicURL +
                  '\nhttps://nft.vividot-de.fi/' +
                  '&hashtags=ビビドットNFTガチャ'
              )
            "
            target="_blank"
          >
            <v-img src="./assets/twitterLogo.png" max-width="30px"></v-img>
          </v-btn>
          <v-btn
            icon
            :href="
              'https://opensea.io/assets/matic/' +
              vividotNFTContract.options.address +
              '/' +
              lastTokenInfo.id
            "
            target="_blank"
          >
            <v-img src="./assets/openSeaLogo.png" max-width="30px"></v-img>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3("wss://ws-matic-mainnet.chainstacklabs.com");
const vividotNFT = require("./vividotNFT.js");
const metadata = require("./metadata.js");

export default {
  name: "App",
  data() {
    return {
      vividotNFTContract: new web3.eth.Contract(
        vividotNFT.abi,
        vividotNFT.address
      ),
      tokens: [],
      balance: 0,
      loading: false,
      waitGacha: false,
      dialog: false,
      lastTokenInfo: {},
      page: 1,
      accounts: [],
      pageLength: 1,
    };
  },
  methods: {
    async gacha() {
      let data = "0x948142bb"; //await this.vividotNFTContract.methods.gacha().encodeABI();
      await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: this.accounts[0],
            to: vividotNFT.address,
            value: 0,
            data: data,
            gasPrice: "0x12A05F200", // 5gwei
            gas: "0x7A120",
          },
        ],
      });
      // 暗くする
      this.waitGacha = true;
    },
    async addToken(tokenId, isUnshift) {
      try {
        let totalSupply = await this.vividotNFTContract.methods
          .totalSupply()
          .call();
        let nftType = await this.vividotNFTContract.methods
          .idType(tokenId)
          .call();
        let nftTypeCount = await this.vividotNFTContract.methods
          .typeCount(nftType)
          .call();

        // 反映が遅く初期値が返ってきてる場合
        if (tokenId != "0" && nftType == "0") {
          nftType = "9999";
          nftTypeCount = 0;
        }
        let imageSrc;
        if (nftType == "0" || nftType == "1") {
          imageSrc = require("./assets/image/" + nftType + ".gif");
        } else {
          imageSrc = require("./assets/image/" + nftType + ".png");
        }

        let tokenData = {
          id: tokenId,
          name: metadata[nftType].name,
          src: imageSrc,
          rarity: ((nftTypeCount / totalSupply) * 100).toFixed(4),
          twitterPicURL: metadata[nftType].twitterPicURL,
          star: metadata[nftType].star,
        };
        if (isUnshift) {
          this.tokens.unshift(tokenData);
        } else {
          this.tokens.push(tokenData);
        }
        if (this.tokens.length >= 25) {
          this.tokens = this.tokens.slice(0, 24);
        }
      } catch (e) {
        console.log(e.message);
        return;
      }
    },
    async setBaseURI() {
      let data = await this.vividotNFTContract.methods
        .setBaseURI(
          "https://gateway.pinata.cloud/ipfs/QmY2hdKvLpCDmdG6VfJVmN1ixvubwAYdSbvBEYRj6ETyuW"
        )
        .encodeABI();
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: accounts[0],
            to: vividotNFT.address,
            value: 0,
            data: data,
            gas: "0x7A120",
          },
        ],
      });
    },
    async changePage(pageNumber) {
      this.loadNFT(pageNumber);
    },
    async loadNFT(pageNumber) {
      this.tokens = [];
      this.loading = true;
      this.balance = await this.vividotNFTContract.methods
        .balanceOf(this.accounts[0])
        .call();

      this.pageLength = Math.ceil(this.balance / 24);

      if (this.balance != "0") {
        for (
          let loadId = Math.max(0, this.balance - 24 * (pageNumber - 1) - 1);
          loadId >= Math.max(0, this.balance - 24 * pageNumber);
          loadId--
        ) {
          let tokenId = await this.vividotNFTContract.methods
            .tokenOfOwnerByIndex(this.accounts[0], loadId)
            .call();
          this.addToken(tokenId, false);
        }
      }
      this.loading = false;
    },
  },
  async mounted() {
    window.ethereum.on("accountsChanged", function () {
      window.location.reload();
    });

    try {
      this.accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
    } catch (addError) {
      alert("アカウントの接続時にエラーが出ました\n" + addError.message);
      return;
    }

    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x89",
            chainName: "Polygon",
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ],
      });
    } catch (addError) {
      alert("ネットワークの接続時にエラーが出ました\n" + addError.message);
      return;
    }

    this.vividotNFTContract.events.Transfer(
      {
        filter: { from: 0x0, to: this.accounts[0] },
        fromBlock: "latest",
      },
      async function (error, event) {
        if (error != null) {
          alert("エラーが出ました\n" + error.message);
          return;
        }
        await this.addToken(event.returnValues.tokenId, true);
        this.lastTokenInfo = this.tokens[0];
        this.dialog = true;
        this.waitGacha = false;
      }.bind(this)
    );

    this.loadNFT(1, false);
  },
};
</script>
